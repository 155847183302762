import { vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "messaging-service-list",
  class: "relative bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen w-full"
}
const _hoisted_2 = {
  id: "messaging-service-wrapper",
  class: "flex flex-col justify-between items-start border-b border-gray-lp-400 z-20 relative pt-8"
}
const _hoisted_3 = { class: "flex flex-row py-6 align-middle items-center justify-between w-full text-black-lp-300" }
const _hoisted_4 = {
  id: "messaging-service-header",
  class: "text-20px text-black-lp-200 flex flex-row items-center flex-no-wrap relative"
}
const _hoisted_5 = {
  id: "header-title",
  class: "mr-8 font-semibold",
  style: {"align-self":"center"}
}
const _hoisted_6 = {
  key: 1,
  class: "h-full overflow-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_AdvancedFilter = _resolveComponent("AdvancedFilter")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _withDirectives(_createVNode("div", {
        id: "advanced-filter-overlay",
        class: "fixed right-0 h-full z-20",
        style: 
          `background-color:rgba(0,0,0,0.3); top: 9rem; width: calc(100% - ${
            _ctx.isAdvancedFilterOpened && _ctx.isCollapseSidebar ? `60px` : `264px`
          });`
        
      }, null, 4), [
        [_vShow, _ctx.isAdvancedFilterOpened]
      ]),
      _createVNode(_component_Breadcrumb, { id: "breadcrumb" }),
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode("span", _hoisted_5, _toDisplayString(_ctx.getTitle), 1),
          _createVNode(_component_AdvancedFilter, {
            id: "advanced-filter",
            modelValue: _ctx.advancedFilterData,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.advancedFilterData = $event)),
            isType: "",
            isMessagingEventStatus: "",
            isMessagingMessageStatus: "",
            titleTypeCustom: "Tipe Pesan",
            customTypeList: _ctx.typeList,
            isScroll: false,
            isDisabled: false,
            onOpened: _ctx.onOpenedAdvancedFilter,
            onReset: _ctx.onResetAdvancedFilter
          }, null, 8, ["modelValue", "customTypeList", "onOpened", "onReset"])
        ]),
        (_ctx.ableToCreate)
          ? (_openBlock(), _createBlock(_component_lp_button, {
              key: 0,
              onClick: _ctx.goToCreate,
              title: "Buat Pesan",
              textColor: "white"
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    (
        !_ctx.isError &&
          !_ctx.isLoading &&
          _ctx.messageserviceList.length === 0 &&
          !_ctx.isFilter &&
          _ctx.pagination.page === 1
      )
      ? (_openBlock(), _createBlock(_component_EmptyState, {
          key: 0,
          customMessage: "Oops.. Belum ada data yang dapat ditampilkan"
        }))
      : (_openBlock(), _createBlock("div", _hoisted_6, [
          _createVNode(_component_TableV2, {
            columns: _ctx.columns,
            data: _ctx.messageserviceList,
            loading: _ctx.isLoading,
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[2] || (_cache[2] = ($event: any) => (_ctx.pagination = $event)),
            totalColumnFreeze: 0,
            isRightFreeze: "",
            onRequest: _ctx.fetchMessageServiceList,
            errorCause: _ctx.errorCause,
            isDetailAble: _ctx.ableToViewDetail,
            onClick: _ctx.onClickRow,
            onTryAgain: _ctx.fetchMessageServiceList,
            paginationStyle: "v3"
          }, null, 8, ["columns", "data", "loading", "pagination", "onRequest", "errorCause", "isDetailAble", "onClick", "onTryAgain"])
        ]))
  ]))
}