
import { Options, Vue } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import RadioButton from "primevue/radiobutton";
import Checkbox from "primevue/checkbox";
import { MessagingServiceConfigurationController } from "@/app/ui/controllers/MessagingServiceConfigurationController";
import { ProductController } from "@/app/ui/controllers/ProductController";
import {
  APPLIED_TO_MESSAGE_OPTIONS,
  MESSAGE_TYPE_OPTIONS,
  EVENT_STATUS_OPTIONS,
  TARGET_MESSAGE_OPTIONS,
  PACKAGE_TYPE_OPTIONS
} from "@/app/ui/views/messaging-service-configuration/MessagingServiceConfigurationUtils";

@Options({
  components: {
    Title,
    RadioButton,
    Checkbox
  }
})
export default class MessagingServiceConfigurationFormMessageInformationSection extends Vue {
  mounted() {
    this.fetchProducts();
    if (this.isEditPage) {
      this.tempData = JSON.parse(
        JSON.stringify({
          posPartners: this.form.posPartners,
          posPartnersExclude: this.form.posPartnersExclude,
          clientBranches: this.form.clientBranches,
          clientBranchesExclude: this.form.clientBranchesExclude
        })
      );
    }
  }
  get isFormTypeEdit() {
    return this.$route.meta.formType === "edit";
  }
  get form() {
    return MessagingServiceConfigurationController.messageServiceForm;
  }
  get showSelectPOS() {
    return this.form.appliedTo.includes("pos");
  }
  get showSelectClientBranch() {
    return this.form.appliedTo.includes("client");
  }

  tempData: any = {};

  get isEditPage() {
    return this.$route.path?.includes("edit");
  }
  onCheckSTTCreatedFor(val: string) {
    if (!this.showSelectPOS) {
      this.form.posPartners = [];
      this.form.posPartnersExclude = [];
      return;
    }
    if (!this.showSelectClientBranch) {
      this.form.clientBranches = [];
      this.form.clientBranchesExclude = [];
      return;
    }
    if (this.isEditPage) {
      if (this.showSelectPOS && val === "pos") {
        this.form.posPartners = JSON.parse(
          JSON.stringify(this.tempData.posPartners)
        );
        this.form.posPartnersExclude = JSON.parse(
          JSON.stringify(this.tempData.posPartnersExclude)
        );
        return;
      } else {
        this.form.clientBranches = JSON.parse(
          JSON.stringify(this.tempData.clientBranches)
        );
        this.form.clientBranchesExclude = JSON.parse(
          JSON.stringify(this.tempData.clientBranchesExclude)
        );
      }
    }
  }

  // tipe pesan
  get messageTypeOptions() {
    return MESSAGE_TYPE_OPTIONS;
  }

  // status berita
  get eventStatusOptions() {
    return EVENT_STATUS_OPTIONS;
  }
  onChangeEventStatus(val: string) {
    if (val === "CODREJ") {
      this.form.packageType = "cod";
    }
  }

  onChangeEventMessageType(val: string) {
    if (val !== "wa_premium") {
      this.form.templateName = "";
    }
  }

  // tipe paket
  get packageTypeOptions() {
    return PACKAGE_TYPE_OPTIONS;
  }

  // target
  get targetOptions() {
    return TARGET_MESSAGE_OPTIONS;
  }

  // dibuat untuk
  get appliedToOptions() {
    return APPLIED_TO_MESSAGE_OPTIONS;
  }

  // product
  listProduct: any = [];
  async fetchProducts() {
    this.listProduct = await ProductController.fetchProductApi({
      page: 1,
      limit: 99,
      status: "active",
      isNoLimit: true
    });
  }
  get productOptions() {
    return this.listProduct.map((e: any) => ({
      name: e.name,
      value: e.name
    }));
  }
}
