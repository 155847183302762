
import { Options, Vue } from "vue-class-component";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import { MessageDictionary } from "@/domain/entities/MessagingServiceConfiguration";
import { MessagingServiceConfigurationController } from "@/app/ui/controllers/MessagingServiceConfigurationController";
@Options({
  components: {
    Skeleton
  }
})
export default class MessagingServiceConfigurationDictionary extends Vue {
  dictionary: MessageDictionary = new MessageDictionary();
  loadingDictionary = false;
  async mounted() {
    this.loadingDictionary = true;
    this.dictionary = await MessagingServiceConfigurationController.fetchMessageDictionary();
    this.loadingDictionary = false;
  }
  activeTab = "tag_definition";
  styleButton(tab: string) {
    if (tab === this.activeTab) {
      return {
        outline: false,
        textColor: "white"
      };
    }
    return {
      outline: true,
      textColor: "red-lp-300"
    };
  }
  activateTab(tab: string) {
    this.activeTab = tab;
  }
}
