import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "space-y-6 ml-4 pb-10" }
const _hoisted_2 = { class: "flex gap-y-6 flex-row flex-wrap" }
const _hoisted_3 = { class: "my-1" }
const _hoisted_4 = {
  key: 1,
  class: "text-12px xxl:text-14px text-black-lp-300 capitalize"
}
const _hoisted_5 = { class: "my-1" }
const _hoisted_6 = {
  key: 1,
  class: "text-12px xxl:text-14px text-black-lp-300 capitalize"
}
const _hoisted_7 = { class: "my-1" }
const _hoisted_8 = {
  key: 1,
  class: "text-12px xxl:text-14px text-black-lp-300 capitalize"
}
const _hoisted_9 = { class: "my-1" }
const _hoisted_10 = {
  key: 1,
  class: "text-12px xxl:text-14px text-black-lp-300 capitalize"
}
const _hoisted_11 = { class: "my-1" }
const _hoisted_12 = {
  key: 1,
  class: "text-12px xxl:text-14px text-black-lp-300 capitalize"
}
const _hoisted_13 = { class: "my-1" }
const _hoisted_14 = {
  key: 1,
  class: "text-12px xxl:text-14px text-black-lp-300 capitalize"
}
const _hoisted_15 = { class: "my-1" }
const _hoisted_16 = {
  key: 1,
  class: "text-12px xxl:text-14px text-black-lp-300 capitalize"
}
const _hoisted_17 = { class: "my-1" }
const _hoisted_18 = {
  key: 1,
  class: "text-12px xxl:text-14px text-black-lp-300 capitalize"
}
const _hoisted_19 = { class: "flex capitalize mt-1" }
const _hoisted_20 = { class: "flex flex-col my-1" }
const _hoisted_21 = { class: "text-left" }
const _hoisted_22 = { class: "text-gray-lp-600" }
const _hoisted_23 = { class: "flex flex-col my-1" }
const _hoisted_24 = { class: "text-left" }
const _hoisted_25 = { class: "text-gray-lp-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_DetailLayout = _resolveComponent("DetailLayout")!

  return (_openBlock(), _createBlock(_component_DetailLayout, {
    scrollSidebar: "",
    customClass: "px-0",
    onBack: _ctx.goBack,
    title: _ctx.id.toString(),
    loading: _ctx.isLoading,
    error: !!_ctx.errorCause,
    errorType: _ctx.errorCause,
    onTryAgain: _ctx.fetchDetailData
  }, {
    data: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_Title, { label: "Informasi SMS" }),
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_DataWrapper, {
            class: "w-full",
            label: "ID Konfigurasi",
            value: _ctx.detailData.msgConfId
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-full",
            label: "Judul Pesan",
            value: _ctx.detailData.msgConfName
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-1/2 capitalize",
            label: "Tipe Pesan",
            value: _ctx.formatJsonToString(_ctx.detailData.msgConfType)
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-1/2",
            label: "Status Berita",
            value: _ctx.detailData.msgConfSttLastStatus
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-1/2 capitalize",
            label: "Tipe Paket",
            value: _ctx.formatJsonToString(_ctx.detailData.msgConfPacketType)
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-1/2 capitalize",
            label: "Target"
          }, {
            default: _withCtx(() => [
              _createVNode("div", _hoisted_3, [
                (_ctx.detailData.msgConfTarget)
                  ? (_openBlock(true), _createBlock(_Fragment, { key: 0 }, _renderList(_ctx.stringToArray(
                    _ctx.detailData.msgConfTarget
                  ), (item, index) => {
                      return (_openBlock(), _createBlock("p", {
                        key: index,
                        class: "my-1 text-12px xxl:text-14px text-black-lp-300 capitalize"
                      }, _toDisplayString(_ctx.formatJsonToString(item)), 1))
                    }), 128))
                  : (_openBlock(), _createBlock("p", _hoisted_4, " - "))
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_DataWrapper, {
            class: "w-1/2",
            label: "STT Dibuat Untuk"
          }, {
            default: _withCtx(() => [
              _createVNode("div", _hoisted_5, [
                (_ctx.detailData.msgConfBookedFor)
                  ? (_openBlock(true), _createBlock(_Fragment, { key: 0 }, _renderList(_ctx.stringToArray(
                    _ctx.detailData.msgConfBookedFor
                  ), (item, index) => {
                      return (_openBlock(), _createBlock("p", {
                        key: index,
                        class: "my-1 text-12px xxl:text-14px text-black-lp-300 capitalize"
                      }, _toDisplayString(_ctx.convertToUppercase(item)), 1))
                    }), 128))
                  : (_openBlock(), _createBlock("p", _hoisted_6, " - "))
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_DataWrapper, {
            class: "w-1/2",
            label: "Produk"
          }, {
            default: _withCtx(() => [
              _createVNode("div", _hoisted_7, [
                (_ctx.detailData.msgConfProduct)
                  ? (_openBlock(true), _createBlock(_Fragment, { key: 0 }, _renderList(_ctx.stringToArray(
                    _ctx.detailData.msgConfProduct
                  ), (item, index) => {
                      return (_openBlock(), _createBlock("p", {
                        key: index,
                        class: "my-1 text-12px xxl:text-14px text-black-lp-300 capitalize"
                      }, _toDisplayString(item), 1))
                    }), 128))
                  : (_openBlock(), _createBlock("p", _hoisted_8, " - "))
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_DataWrapper, {
            class: "w-1/2",
            label: "Rute",
            value: _ctx.mappingRouteMsgConfig(_ctx.detailData)
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-1/2",
            label: "SLA",
            value: _ctx.SLAValue
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-1/2",
            label: "Kota Asal",
            value: _ctx.detailData.msgConfOriginCity
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-1/2",
            label: "Pengecualian Kota Asal"
          }, {
            default: _withCtx(() => [
              _createVNode("div", _hoisted_9, [
                (_ctx.detailData.msgConfOriginCityExclude)
                  ? (_openBlock(true), _createBlock(_Fragment, { key: 0 }, _renderList(_ctx.stringToArray(
                    _ctx.detailData.msgConfOriginCityExclude
                  ), (item, index) => {
                      return (_openBlock(), _createBlock("p", {
                        key: index,
                        class: "my-1 text-12px xxl:text-14px text-black-lp-300 capitalize"
                      }, _toDisplayString(item), 1))
                    }), 128))
                  : (_openBlock(), _createBlock("p", _hoisted_10, " - "))
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_DataWrapper, {
            class: "w-1/2",
            label: "Kota Tujuan",
            value: _ctx.detailData.msgConfDestinationCity
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-1/2",
            label: "Pengecualian Kota Tujuan"
          }, {
            default: _withCtx(() => [
              _createVNode("div", _hoisted_11, [
                (_ctx.detailData.msgConfDestinationCityExclude)
                  ? (_openBlock(true), _createBlock(_Fragment, { key: 0 }, _renderList(_ctx.stringToArray(
                    _ctx.detailData.msgConfDestinationCityExclude
                  ), (item, index) => {
                      return (_openBlock(), _createBlock("p", {
                        key: index,
                        class: "my-1 text-12px xxl:text-14px text-black-lp-300 capitalize"
                      }, _toDisplayString(item), 1))
                    }), 128))
                  : (_openBlock(), _createBlock("p", _hoisted_12, " - "))
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_DataWrapper, {
            class: "w-1/2",
            label: "Nama POS",
            value: _ctx.convertToUppercase(_ctx.detailData.msgConfPosName?.replaceAll(';', ','))
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-1/2",
            label: "Pengecualian POS"
          }, {
            default: _withCtx(() => [
              _createVNode("div", _hoisted_13, [
                (_ctx.detailData.msgConfPosNameExclude)
                  ? (_openBlock(true), _createBlock(_Fragment, { key: 0 }, _renderList(_ctx.stringToArray(
                    _ctx.detailData.msgConfPosNameExclude?.replaceAll(';', ',')
                  ), (item, index) => {
                      return (_openBlock(), _createBlock("p", {
                        key: index,
                        class: "my-1 text-12px xxl:text-14px text-black-lp-300 capitalize"
                      }, _toDisplayString(_ctx.convertToUppercase(item)), 1))
                    }), 128))
                  : (_openBlock(), _createBlock("p", _hoisted_14, " - "))
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_DataWrapper, {
            class: "w-1/2",
            label: "Nama Client Cabang",
            value: _ctx.detailData.msgConfClientName?.replaceAll(';', ',')
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-1/2",
            label: "Pengecualian Client Cabang"
          }, {
            default: _withCtx(() => [
              _createVNode("div", _hoisted_15, [
                (_ctx.detailData.msgConfClientNameExclude)
                  ? (_openBlock(true), _createBlock(_Fragment, { key: 0 }, _renderList(_ctx.stringToArray(
                    _ctx.detailData.msgConfClientNameExclude?.replaceAll(';', ',')
                  ), (item, index) => {
                      return (_openBlock(), _createBlock("p", {
                        key: index,
                        class: "my-1 text-12px xxl:text-14px text-black-lp-300 capitalize"
                      }, _toDisplayString(item), 1))
                    }), 128))
                  : (_openBlock(), _createBlock("p", _hoisted_16, " - "))
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_DataWrapper, {
            class: "w-1/2",
            label: "Nama Client Parent",
            value: _ctx.detailData.msgConfClientParentName?.replaceAll(';', ',')
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-1/2",
            label: "Pengecualian Client Parent"
          }, {
            default: _withCtx(() => [
              _createVNode("div", _hoisted_17, [
                (_ctx.detailData.msgConfClientParentNameExclude)
                  ? (_openBlock(true), _createBlock(_Fragment, { key: 0 }, _renderList(_ctx.stringToArray(
                    _ctx.detailData.msgConfClientParentNameExclude?.replaceAll(';', ',')
                  ), (item, index) => {
                      return (_openBlock(), _createBlock("p", {
                        key: index,
                        class: "my-1 text-12px xxl:text-14px text-black-lp-300 capitalize"
                      }, _toDisplayString(item), 1))
                    }), 128))
                  : (_openBlock(), _createBlock("p", _hoisted_18, " - "))
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_DataWrapper, {
            class: "w-full",
            label: "Periode Pesan",
            value: _ctx.periodeDate
          }, null, 8, ["value"]),
          _createVNode(_component_Title, { label: "Detail Pesan" }),
          _createVNode(_component_DataWrapper, {
            class: "w-full",
            label: "Isi Pesan",
            value: _ctx.detailData.msgConfMessageText
          }, null, 8, ["value"])
        ])
      ])
    ]),
    "sub-data": _withCtx(() => [
      _createVNode(_component_DataWrapper, {
        class: "w-full",
        label: "Status Pesan"
      }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_19, [
            _createVNode("div", {
              class: _ctx.onChipStatus(_ctx.detailData.msgConfStatus)
            }, _toDisplayString(_ctx.detailData.msgConfStatus), 3)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_DataWrapper, {
        class: "w-full",
        label: "Tanggal Dibuat"
      }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_20, [
            _createVNode("div", _hoisted_21, [
              _createTextVNode(_toDisplayString(_ctx.formatDate(_ctx.detailData.msgConfCreatedAt)) + " ", 1),
              _createVNode("div", _hoisted_22, _toDisplayString(_ctx.detailData.msgConfCreatedBy || "-"), 1)
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_DataWrapper, {
        class: "w-full",
        label: "Tanggal Diperbarui"
      }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_23, [
            _createVNode("div", _hoisted_24, [
              _createTextVNode(_toDisplayString(_ctx.formatDate(
                _ctx.detailData.msgConfUpdatedAt || _ctx.detailData.msgConfCreatedAt
              )) + " ", 1),
              _createVNode("div", _hoisted_25, _toDisplayString(_ctx.detailData.msgConfUpdatedBy ||
                  _ctx.detailData.msgConfCreatedBy ||
                  "-"), 1)
            ])
          ])
        ]),
        _: 1
      }),
      (_ctx.ableToEdit)
        ? (_openBlock(), _createBlock(_component_LpButton, {
            key: 0,
            title: "Ubah",
            onClick: _ctx.goToEdit,
            textColor: "white"
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onBack", "title", "loading", "error", "errorType", "onTryAgain"]))
}