import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"width":"50vw"} }
const _hoisted_2 = { class: "flex gap-x-6 py-3" }
const _hoisted_3 = {
  class: "py-3 overflow-y-auto",
  style: {"max-height":"60vh"}
}
const _hoisted_4 = { class: "py-2 px-2 font-semibold text-black-lp-300" }
const _hoisted_5 = { class: "w-8/12" }
const _hoisted_6 = { class: "flex w-4/12" }
const _hoisted_7 = { class: "text-black-lp-300 w-auto rounded-md border py-1 px-3 border-black-lp-300" }
const _hoisted_8 = { class: "w-8/12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_chips = _resolveComponent("chips")!
  const _component_Skeleton = _resolveComponent("Skeleton")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (!_ctx.loadingDictionary)
      ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
          _createVNode("div", _hoisted_2, [
            _createVNode(_component_lp_button, _mergeProps(_ctx.styleButton('tag_definition'), {
              rounded: "",
              compact: "",
              "custom-class": "py-1 px-3",
              title: "Definisi Tag",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.activateTab('tag_definition')))
            }), null, 16),
            _createVNode(_component_lp_button, _mergeProps(_ctx.styleButton('tag_format'), {
              rounded: "",
              compact: "",
              "custom-class": "py-1 px-3",
              title: "Format Tag",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.activateTab('tag_format')))
            }), null, 16)
          ]),
          _createVNode("div", _hoisted_3, [
            (_ctx.activeTab === 'tag_definition')
              ? (_openBlock(true), _createBlock(_Fragment, { key: 0 }, _renderList(_ctx.dictionary.tagDefinition, (tagDefinition) => {
                  return (_openBlock(), _createBlock("div", {
                    key: tagDefinition.tag,
                    class: "flex items-center py-3"
                  }, [
                    _createVNode(_component_chips, {
                      class: "w-4/12",
                      color: "gray-lp-400"
                    }, {
                      default: _withCtx(() => [
                        _createVNode("div", _hoisted_4, _toDisplayString(tagDefinition.tag), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode("div", _hoisted_5, ": " + _toDisplayString(tagDefinition.value), 1)
                  ]))
                }), 128))
              : _createCommentVNode("", true),
            (_ctx.activeTab === 'tag_format')
              ? (_openBlock(true), _createBlock(_Fragment, { key: 1 }, _renderList(_ctx.dictionary.tagFormat, (tagFormat) => {
                  return (_openBlock(), _createBlock("div", {
                    key: tagFormat.tag,
                    class: "flex items-center py-3"
                  }, [
                    _createVNode("div", _hoisted_6, [
                      _createVNode("div", _hoisted_7, _toDisplayString(tagFormat.tag), 1)
                    ]),
                    _createVNode("div", _hoisted_8, _toDisplayString(tagFormat.value.split(",").join(", ")), 1)
                  ]))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ], 64))
      : (_openBlock(), _createBlock(_Fragment, { key: 1 }, _renderList(8, (i) => {
          return _createVNode("div", {
            key: i,
            class: "flex flex-row gap-x-16 py-3"
          }, [
            _createVNode(_component_Skeleton, {
              width: "20rem",
              height: "2rem"
            }),
            _createVNode(_component_Skeleton, { height: "2rem" })
          ])
        }), 64))
  ]))
}