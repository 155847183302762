
import { ALLOWED } from "@/app/infrastructures/misc/RolePermission";
import {
  checkRolePermission,
  dateToDateString
} from "@/app/infrastructures/misc/Utils";
import { Vue, Options } from "vue-class-component";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import { MessagingServiceConfigurationController } from "@/app/ui/controllers/MessagingServiceConfigurationController";
import { AccountController } from "../../controllers/AccountController";
import debounce from "lodash/debounce";
import { MessagingServiceConfigurationListData } from "@/domain/entities/MessagingServiceConfiguration";
import { MESSAGE_TYPE_OPTIONS } from "@/app/ui/views/messaging-service-configuration/MessagingServiceConfigurationUtils";

@Options({
  components: {
    EmptyState
  }
})
export default class MessagingServiceConfiguration extends Vue {
  mounted() {
    MessagingServiceConfigurationController.initMessageServiceList();
  }

  get getTitle() {
    return this.$route.meta.name;
  }
  get ableToCreate() {
    return checkRolePermission(ALLOWED);
  }
  get ableToViewDetail() {
    return checkRolePermission(ALLOWED);
  }

  setFirstPage() {
    MessagingServiceConfigurationController.setFirstPage();
  }
  fetchMessageServiceList() {
    MessagingServiceConfigurationController.fetchMessageServiceList();
  }
  get isLoading() {
    return MessagingServiceConfigurationController.isLoading;
  }
  get messageserviceList() {
    return MessagingServiceConfigurationController.messageServiceList.data;
  }
  get pagination() {
    return MessagingServiceConfigurationController.messageServiceList
      .pagination;
  }

  // advanced filter
  isAdvancedFilterOpened = false;
  onOpenedAdvancedFilter(event: any) {
    this.isAdvancedFilterOpened = event;
  }
  get advancedFilterData() {
    return MessagingServiceConfigurationController.advancedFilterData;
  }
  set advancedFilterData(val: any) {
    MessagingServiceConfigurationController.setAdvancedFilterData(val);
    this.setFirstPage();
    this.fetchMessageServiceList();
  }
  onResetAdvancedFilter = debounce(() => {
    MessagingServiceConfigurationController.setAdvancedFilterData({
      type: { name: "", value: "" },
      messagingEventStatus: { name: "", value: "" },
      messagingMessageStatus: { name: "", value: "" }
    });
    this.setFirstPage();
    this.fetchMessageServiceList();
  }, 500);
  get isCollapseSidebar() {
    return AccountController.isCollapseSidebar;
  }
  get typeList() {
    return MESSAGE_TYPE_OPTIONS;
  }

  // table
  get columns() {
    return [
      {
        name: "ID Konfig.",
        styleHead:
          "w-40 text-left whitespace-no-wrap align-top text-black-lp-300",
        render: (row: MessagingServiceConfigurationListData) => {
          return `<div class="overflow-ellipsis text-left text-black-lp-300">${row.msgConfCode}</div>`;
        }
      },
      {
        name: "Tipe Pesan",
        styleHead:
          "w-36 text-left whitespace-no-wrap align-top text-black-lp-300",
        render: (row: MessagingServiceConfigurationListData) => {
          return `<div class=" overflow-ellipsis text-left text-black-lp-300 capitalize">${row.msgConfType
            .replace("_", " ")
            .replace("sms", "SMS")
            .replace("wa", "WA")}</div>`;
        }
      },
      {
        name: "Status Berita",
        styleHead:
          "w-28 text-left whitespace-no-wrap align-top text-black-lp-300",
        render: (row: MessagingServiceConfigurationListData) => {
          return `<div class=" overflow-ellipsis text-left text-black-lp-300">${row.msgConfSttLastStatus}</div>`;
        }
      },
      {
        name: "Tipe Paket",
        styleHead:
          "w-28 text-left whitespace-no-wrap align-top text-black-lp-300",
        render: (row: MessagingServiceConfigurationListData) => {
          return `<div class=" overflow-ellipsis text-left text-black-lp-300">${row.msgConfPacketType
            .replace("_", " ")
            .replace("cod", "COD")
            .replace("dfod", "DFOD")
            }</div>`;
        }
      },
      {
        name: "Target",
        styleHead:
          "w-40 text-left whitespace-no-wrap align-top text-black-lp-300",
        render: (row: MessagingServiceConfigurationListData) => {
          return `<div class=" overflow-ellipsis text-left text-black-lp-300 capitalize">${row.msgConfTarget.replace(
            /,|_/g,
            " "
          )}</div>`;
        }
      },
      {
        name: "Status Pesan",
        styleHead:
          "w-40 text-left whitespace-no-wrap align-top text-black-lp-300",
        render: (row: MessagingServiceConfigurationListData) => {
          return `<div class="rounded-full capitalize overflow-ellipsis text-left px-2 flex flex-row gap-x-1 font-medium ${
            row.msgConfStatus.toLowerCase() === "active"
              ? "text-green-lp-200 bg-green-lp-300"
              : row.msgConfStatus.toLowerCase() === "expired"
              ? "text-red-lp-500 bg-red-lp-600"
              : row.msgConfStatus.toLowerCase() === "waiting" ||
                row.msgConfStatus.toLowerCase() === "pending"
              ? "text-black-lp-300 bg-gray-lp-200"
              : "text-yellow-lp-100 bg-yellow-lp-200"
          }">
            ${
              row.msgConfStatus === "pending"
                ? "Waiting"
                : row.msgConfStatus.replace(/_|-/g, " ")
            }
          </div>`;
        }
      },
      {
        name: "Kota Asal",
        styleHead:
          "w-40 text-left whitespace-no-wrap align-top text-black-lp-300",
        render: (row: MessagingServiceConfigurationListData) => {
          return `<div class=" overflow-ellipsis text-left text-black-lp-300">${row.msgConfOriginCity.replace(
            /,/g,
            ", "
          )}</div>`;
        }
      },
      {
        name: "Produk",
        styleHead:
          "w-28 text-left whitespace-no-wrap align-top text-black-lp-300",
        render: (row: MessagingServiceConfigurationListData) => {
          return `<div class=" overflow-ellipsis text-left text-black-lp-300">${row.msgConfProduct.replace(
            /,/g,
            " "
          )}</div>`;
        }
      },
      {
        name: "Tanggal Dibuat",
        styleHead: "w-40 text-left align-top text-black-lp-300",
        render: (row: MessagingServiceConfigurationListData) => {
          return `<div class="text-black-lp-300 flex flex-col capitalize text-left">${this.formatDate(
            row.msgConfCreatedAt
          )}<span class="text-gray-lp-500 ${
            row.msgConfCreatedBy.split(" ").length > 1 ? "" : "break-all"
          }">${row.msgConfCreatedBy}</span></div>`;
        }
      },
      {
        name: "Tanggal Diubah",
        styleHead: "w-40 text-left align-top text-black-lp-300",
        render: (row: MessagingServiceConfigurationListData) => {
          return `<div class="text-black-lp-300 flex flex-col capitalize text-left">${
            row.msgConfUpdatedAt ? this.formatDate(row.msgConfUpdatedAt) : "-"
          }<span class="text-gray-lp-500 ${
            row.msgConfUpdatedBy.split(" ").length > 1 ? "" : "break-all"
          }">${row.msgConfUpdatedBy ? row.msgConfUpdatedBy : ""}</span></div>`;
        }
      }
    ];
  }

  formatDate(date: any) {
    date = date ? new Date(date) : "";
    return date ? dateToDateString(date) : "";
  }

  onClickRow(row: any) {
    this.$router.push(
      `/admin/messaging-service-configuration/${row.msgConfId}`
    );
  }

  // goToCreate
  goToCreate() {
    this.$router.push("/admin/messaging-service-configuration/add");
  }

  get isFilter() {
    return (
      this.advancedFilterData.type.value ||
      this.advancedFilterData.messagingEventStatus.value ||
      this.advancedFilterData.messagingMessageStatus.value
    );
  }

  get isError() {
    return MessagingServiceConfigurationController.error;
  }

  get errorCause() {
    return MessagingServiceConfigurationController.errorCause;
  }
}
