
import { Options, Vue } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { MessagingServiceConfigurationController } from "@/app/ui/controllers/MessagingServiceConfigurationController";
import MessagingServiceConfigurationDictionary from "@/app/ui/views/messaging-service-configuration/messaging-service-configuration-form/messaging-service-configuration-dictionary.vue";
import ContentEditAble from "vue-contenteditable";
import debounce from "lodash/debounce";
import {
  MessageDictionary,
  TagValue,
  TemplateWhatsappPremium
} from "@/domain/entities/MessagingServiceConfiguration";
import { MainAppController } from "@/app/ui/controllers/MainAppController";

@Options({
  components: {
    MessagingServiceConfigurationDictionary,
    Title,
    ContentEditAble
  }
})
export default class MessagingServiceConfigurationFormMessageDetailSection extends Vue {
  get form() {
    return MessagingServiceConfigurationController.messageServiceForm;
  }
  get watchMessageType() {
    if (this.form.messageType === "wa_premium") {
      this.form.messageDetail = "";
      this.templateWhatsappPremiumSelected = "";
    }
    return "";
  }
  get isMessageTypeWhatsappPremium() {
    return this.form.messageType === "wa_premium";
  }

  dictionary: MessageDictionary = new MessageDictionary();
  get titleAvailableTemplate() {
    const packageType =
      this.form.packageType === "non_cod"
        ? "Non-COD"
        : this.form.packageType.toUpperCase();
    return `${this.form.eventStatus} (${packageType})`;
  }
  get isTemplateAvailable() {
    return this.form.eventStatus && this.form.packageType;
  }
  async fillWithTemplate() {
    MainAppController.showLoading();
    this.dictionary = await MessagingServiceConfigurationController.fetchMessageDictionary();
    MainAppController.closeLoading();
    const content = this.dictionary?.tagFormat.find((format: TagValue) => {
      return (
        format.keyStatus === this.form.eventStatus &&
        format.keyType === this.form.packageType
      );
    })?.content;
    if (content) {
      this.form.messageDetail = content;
    }
  }

  dialogDictionary = false;
  showDictionary() {
    this.dialogDictionary = true;
  }

  get maxLengthMessage() {
    if (this.form.messageType.includes("sms")) {
      return 500;
    }
    return 3000;
  }
  onInputMessage(val: string) {
    if (val.length > this.maxLengthMessage) {
      return;
    }
    this.form.messageDetail = val;
  }
  validateInput(event: any) {
    if (event.target.innerText.length >= this.maxLengthMessage) {
      event.preventDefault();
    }
  }

  loadTemplateWhatsappPremium = false;
  templateWhatsappPremiumOptions: TemplateWhatsappPremium[] = [];
  templateWhatsappPremiumSelected: any = "";
  async fetchTemplateWhatsappPremium(search: string) {
    this.loadTemplateWhatsappPremium = true;
    this.templateWhatsappPremiumOptions = await MessagingServiceConfigurationController.fetchTemplateWhatsappPremium(
      {
        templateName: search
      }
    );
    this.loadTemplateWhatsappPremium = false;
  }
  filterTemplateWhatsappPremium = debounce(async (search: string) => {
    await this.fetchTemplateWhatsappPremium(search);
  }, 250);
  onSelectTemplateWhatsappPremium(value: TemplateWhatsappPremium) {
    this.form.messageDetail = value.templateContent;
    this.form.templateName = value.templateName;
  }
}
