import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "inline-flex" }
const _hoisted_2 = { class: "w-10/12 space-y-6 pb-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_MessagingServiceConfigurationFormMessageInformationSection = _resolveComponent("MessagingServiceConfigurationFormMessageInformationSection")!
  const _component_MessagingServiceConfigurationFormMessageInformationSection2 = _resolveComponent("MessagingServiceConfigurationFormMessageInformationSection2")!
  const _component_MessagingServiceConfigurationFormMessageDetailSection = _resolveComponent("MessagingServiceConfigurationFormMessageDetailSection")!
  const _component_DetailLayout = _resolveComponent("DetailLayout")!

  return (_openBlock(), _createBlock(_component_DetailLayout, {
    onBack: _ctx.goBack,
    title: _ctx.titlePage,
    "full-page": "",
    "custom-class": "px-0",
    "vertical-separator": false,
    "leave-page-confirmation": _ctx.leavePageConfirmation,
    "onUpdate:leave-page-confirmation": _cache[1] || (_cache[1] = ($event: any) => (_ctx.leavePageConfirmation = $event)),
    onLeavePage: _ctx.onLeavePage,
    loading: _ctx.loadingDetail,
    error: _ctx.error,
    "error-type": _ctx.errorCause
  }, {
    "top-right": _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_lp_button, {
          "text-color": "white",
          customClass: "w-full py-2.5",
          title: "Simpan",
          onClick: _ctx.onSaveForm,
          disabled: !_ctx.isFormValid
        }, null, 8, ["onClick", "disabled"])
      ])
    ]),
    data: _withCtx(() => [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_MessagingServiceConfigurationFormMessageInformationSection),
        _createVNode(_component_MessagingServiceConfigurationFormMessageInformationSection2),
        _createVNode(_component_MessagingServiceConfigurationFormMessageDetailSection)
      ])
    ]),
    _: 1
  }, 8, ["onBack", "title", "leave-page-confirmation", "onLeavePage", "loading", "error", "error-type"]))
}